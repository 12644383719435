/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import StockService from "services/stocks";
import { MultiSelect } from "primereact";
import { Panel } from "primereact/panel";
import VUpload from "components/v-upload";

const ALLOW_SHOW = ["top", "dashboard", "right"];
const NETWORK_SETTING = ["bsc", "ethereum", "tron", "internal"];

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    contract: "",
    symbol: "",
    supply: 0,
    logo: "",
    decimal: 0,
    status: "enable",
    allow_show: [],
    pair: "",
    fee: {
      withdraw: 0,
      deposit: 0,
      trade: 0,
    },
    feature: {
      is_withdraw: false,
      is_deposit: false,
      is_trade: false,
    },
    network: [],
    price: 1,
    order: 0,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails((prev) => ({
        ...prev,
        ...data,
      }));
  }, [data]);

  const onChange = (value, name, parent = null) => {
    if (parent) {
      setDetails({
        ...details,
        [parent]: {
          ...details[parent],
          [name]: value,
        },
      });
    } else
      setDetails({
        ...details,
        [name]: value,
      });
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await StockService.updateCryptos({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await StockService.createCryptos({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Stock saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        <VUpload
          urlFile={details.logo}
          setFile={(e) => {
            onChange(e?.filePath, "logo");
          }}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange(e.target.value, "name")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Pair</label>
        <InputText
          id="name"
          value={details.pair}
          onChange={(e) => onChange(e.target.value, "pair")}
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="symbol">Symbol</label>
        <InputText
          id="symbol"
          value={details.symbol}
          onChange={(e) => onChange(e.target.value, "symbol")}
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="symbol">Decimal</label>
        <InputText
          value={details.decimal}
          onChange={(e) => onChange(e.target.value, "decimal")}
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="symbol">Price</label>
        <InputNumber
          id="price"
          value={details.price}
          onChange={(e) => onChange(e.value, "price")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="logo">Logo</label>
        <InputText
          id="logo"
          value={details.logo}
          onChange={(e) => onChange(e.target.value, "logo")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="contract">Contract</label>
        <InputText
          id="contract"
          value={details.contract}
          onChange={(e) => onChange(e.target.value, "contract")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Order</label>
        <InputNumber
          id="stock_order"
          value={details?.order}
          mode="decimal"
          onChange={(e) => onChange(e.value, "order")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
          ]}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-6">
        <label htmlFor="description">Network</label>
        <MultiSelect
          value={details?.network.length > 0 ? details.network : []}
          options={NETWORK_SETTING.map((n) => ({
            label: n,
            value: n,
          }))}
          onChange={(e) => onChange(e.value, "network")}
          placeholder="Select network"
          className={"w-full"}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Allow show</label>
        <MultiSelect
          value={details.allow_show}
          options={ALLOW_SHOW.map((a) => ({
            label: a,
            value: a,
          }))}
          onChange={(e) => onChange(e.value, "allow_show")}
          placeholder="Allow show"
          maxSelectedLabels={3}
          className={"w-full"}
        />
      </div>

      <Panel
        header="Setting Fee"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Trade</label>
            <InputNumber
              id="fee_swap"
              prefix="%"
              value={details.fee.trade}
              mode="decimal"
              minFractionDigits={2}
              onChange={(e) => onChange(e.value, "trade", "fee")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Withdraw</label>
            <InputNumber
              id="fee_transfer"
              prefix="%"
              value={details.fee.withdraw}
              mode="decimal"
              minFractionDigits={2}
              onChange={(e) => onChange(e.value, "withdraw", "fee")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Deposit</label>
            <InputNumber
              id="fee_withdraw"
              prefix="%"
              value={details.fee.deposit}
              mode="decimal"
              minFractionDigits={2}
              onChange={(e) => onChange(e.value, "deposit", "fee")}
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Setting Feature"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          {/* On off option */}
          <div className="field col-6 md:col-3">
            <label htmlFor="swap">Trade</label>
            <br />
            <InputSwitch
              checked={details.feature.is_trade}
              onChange={(e) => onChange(e.value, "is_trade", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="id_region">Withdraw</label>
            <br />
            <InputSwitch
              checked={details.feature.is_withdraw}
              onChange={(e) => onChange(e.value, "is_withdraw", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="id_province">Deposit</label>
            <br />
            <InputSwitch
              checked={details.feature.is_deposit}
              onChange={(e) => onChange(e.value, "is_deposit", "feature")}
            />
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default forwardRef(Details);
