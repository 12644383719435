import React from "react";
import { Route, Switch } from "react-router-dom";
import AppManager from "./pages/app";
import Login from "./pages/login";
import "App.css";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "boxicons/css/boxicons.min.css";
import "assets/layout/layout.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={AppManager} />
      </Switch>
    </div>
  );
}

export default App;
