/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import UploadService from "services/uploads";
import SettingService from "services/settings";
import { Calendar } from "primereact";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  // const [provinces, setProvinces] = useState<any>([]);
  // const [districts, setDistricts] = useState<any>([]);
  // const [wards, setWards] = useState<any>([]);
  // const [cities, setCities] = useState<any>([]);
  // const [province, setProvince] = useState<any>("");
  // const [district, setDistrict] = useState<any>("");
  // const [ward, setWard] = useState<any>("");
  const [imagesPreviewUrl, setImagesPreviewUrl] = useState(null);
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState(null);

  const emptyData = {
    title: "",
    icon: "",
    banner: "",
    content: "",
    link: "",
    file1: "",
    file2: "",
    type: "system",
    preview: null,
    start_date: null,
    end_date: null,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails((prev) => ({
        ...prev,
        ...data,
      }));
    }
  }, [data]);

  // useEffect(() => {
  //   async function fetchCities() {
  //     let response = await axios.get(
  //       "https://api.blockcheck.vn/api/cities/province"
  //     );
  //     setCities(response.data.data);
  //     setProvinces(response.data.data);
  //   }

  //   fetchCities();
  // }, []);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  console.log({ details });

  const handleImageChange = (e: any, type: any) => {
    e.preventDefault();
    const files: any = Array.from(e.target.files);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (type === "icon") {
        setImagesPreviewUrl(reader.result);
        setDetails({
          ...details,
          file1: files[0],
        });
      } else {
        setBannerPreviewUrl(reader.result);
        setDetails({
          ...details,
          file2: files[0],
        });
      }
    };
    reader.readAsDataURL(files[0]);
  };

  // const _provincesSelect = useMemo(() => {
  //   const data =
  //     provinces.map((pro) => ({
  //       label: pro.name,
  //       value: pro.code,
  //     })) || [];
  //   return data;
  // }, [provinces]);

  const submit = async () => {
    try {
      let avatarRes = null;
      let bannerRes = null;
      setLoading(true);
      if (details.file1) {
        const formData = new FormData();
        formData.append("files", details.file1);
        avatarRes = await UploadService.uploadSingle({ body: formData });
      }
      if (details.file2) {
        const formData = new FormData();
        formData.append("files", details.file2);
        bannerRes = await UploadService.uploadSingle({ body: formData });
      }
      setLoading(true);
      if (data) {
        await SettingService.updateNotification({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            // address: {
            //   provices: province,
            //   districts: district,
            //   wards: ward,
            // },
            icon: avatarRes ? avatarRes.filePath : data.icon,
            banner: bannerRes ? bannerRes.filePath : data.banner,
          },
        });
      } else {
        await SettingService.createNotification({
          body: {
            ...details,
            // address: {
            //   provices: province,
            //   districts: district,
            //   wards: ward,
            // },
            icon: avatarRes ? avatarRes.filePath : details.icon,
            banner: bannerRes ? bannerRes.filePath : details.banner,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Notifications saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };
  // const onChangeAddress = async (value, key) => {
  //   try {
  //     if (key === "provinces") {
  //       setWards([]);
  //       setProvince(value);
  //       const districtTemp = cities.filter((i) => i.name === value);
  //       setDistricts(districtTemp[0].districts);
  //     }
  //     if (key === "districts") {
  //       setDistrict(value);
  //       const wardTemp = districts.filter((i) => i.name === value);
  //       setWards(wardTemp[0].wards);
  //     } else {
  //       setWard(value);
  //     }
  //   } catch (error) {}
  // };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={details.title}
          onChange={(e) => onInputChange(e, "title")}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onInputChange(e, "link")}
          required
          autoFocus
        />
      </div>

      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <InputText
          id="content"
          value={details.content}
          onChange={(e) => onInputChange(e, "content")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="start">Start date</label>
        <Calendar
          id="start"
          placeholder="Start date"
          value={details.start_date ? new Date(details.start_date) : undefined}
          onChange={(e: any) => onInputChange(e, "start_date")}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="end">End date</label>
        <Calendar
          id="end"
          placeholder="End date"
          value={details.end_date ? new Date(details.end_date) : undefined}
          onChange={(e: any) => onInputChange(e, "end_date")}
          showTime
          showSeconds
          showButtonBar
        />
      </div>

      {/* <div className="field col-12 md:col-4">
        <label htmlFor="province">Provinces</label>
        <Dropdown
          value={province}
          options={_provincesSelect}
          onChange={(e) => onChangeAddress(e.value, "provinces")}
          optionLabel="label"
          optionValue="label"
          placeholder="Province"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="districts">Districts</label>
        <Dropdown
          value={district}
          options={districts}
          onChange={(e) => onChangeAddress(e.value, "districts")}
          optionLabel="name"
          optionValue="name"
          placeholder="Districts"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="ưards">Wards</label>
        <Dropdown
          value={ward}
          options={wards}
          onChange={(e) => onChangeAddress(e.value, "wards")}
          optionLabel="name"
          optionValue="name"
          placeholder="Wards"
        />
      </div> */}
      <div className="field col-12  md:col-6">
        <img
          src={imagesPreviewUrl ? imagesPreviewUrl : details.icon}
          alt=""
          width={100}
          height={100}
        />
        <input
          accept="image/*"
          id="change-icon"
          type="file"
          multiple
          onChange={(e) => handleImageChange(e, "icon")}
        />
      </div>

      <div className="field col-12  md:col-6">
        <img
          src={bannerPreviewUrl ? bannerPreviewUrl : details.banner}
          alt=""
          width={100}
          height={100}
        />
        <input
          accept="image/*"
          id='change-banner"'
          type="file"
          multiple
          onChange={(e) => handleImageChange(e, "banner")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
