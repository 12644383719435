import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";
import { Toolbar } from "primereact/toolbar";
import Settings from "services/settings";
import { formatLocalTime, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import DetailSupport from "./components/DetailSupports";
import { Calendar, Dropdown, InputText, Panel } from "primereact";
import debounce from "lodash.debounce";
import SupportService from "services/supports";

const items = [{ label: "List support" }, { label: "Setting" }];
const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Replied", value: "replied" },
  { name: "Resolved", value: "resolved" },
  { name: "Not resolved", value: "not_resolved" },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [listSupports, setListSupports] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: STATUS.map((s) => s.value),
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDetailSupport = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const refDetailSupport = useRef(null);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getSupports();
  }, [lazyParams, globalFilter]);

  const handleTab = (index) => {
    setActiveIndex(index);
  };

  const handleDelete = async () => {
    try {
      const newSetting = data.filter((el) => el.id !== detail.id);
      await Settings.updateSetting({
        body: {
          name: "supports",
          value: newSetting,
        },
      }).then((data) => {
        showToast(toast, "success", "Support Deleted");
      });
      setData(newSetting);
      refDialogDelete.current.close();
      setDetail(null);
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  // Filter
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);
  //

  // Get data
  const getSupports = async () => {
    try {
      setLoading(true);
      const res: any = await SupportService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setListSupports(res.docs);
        setTotalRecords(res.totalDocs);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await Settings.getSettingsByName({
        params: {
          name: "supports",
        },
      });
      if (res && res.setting) {
        const value = res.setting["value"] || [];
        setData(value);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancleDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  //DETAIL SUPPORT
  const onSubmitDetailSupport = () => {
    refDetailSupport.current.submit();
  };
  const setLoadingSaveDetailSupport = (flg) => {
    refDialogDetailSupport.current.setLoading(flg);
  };

  const onCancleDialogDetailSupport = () => {
    refDialogDetailSupport.current.close();
  };
  const editSupport = (data) => {
    setDetail({ ...data });
    refDialogDetailSupport.current.show();
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  // Filter
  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block col-12 md:col-6 p-input-icon-left">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-6">
            <Dropdown
              value={globalFilter.status}
              options={STATUS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              className={"w-full"}
            />
          </div>
          <Calendar
            className="col-6"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
          <Calendar
            className="col-6"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );
  //

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-1"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const actionBodyTemplateList = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-1"
          onClick={() => editSupport(rowData)}
        />
      </div>
    );
  };

  const _dataShow = data;

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => handleTab(e.index)}
          />

          {activeIndex === 1 && (
            <DataTable
              ref={dt}
              value={_dataShow}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
              emptyMessage="No data found."
              header={<></>}
              showGridlines
              scrollable
              size="small"
              scrollDirection="both"
              loading={loading}
            >
              <Column
                body={actionBodyTemplate}
                style={{ flexGrow: 1, flexBasis: "110px" }}
              />
              <Column
                field="title"
                header="Title"
                sortable
                style={{ flexGrow: 1, flexBasis: "350px" }}
              />

              <Column
                field="fields"
                header="Field"
                sortable
                style={{ flexGrow: 1, flexBasis: "300px" }}
                body={(rowData) => <span>{rowData.fields.length}</span>}
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "200px" }}
                field="attachments"
                header="Attachments"
                sortable
                body={(rowData) => (
                  <span className={`v-badge`}>
                    {rowData.attachments.enable ? "Required" : "No"}
                  </span>
                )}
              />
            </DataTable>
          )}

          {activeIndex === 0 && (
            <DataTable
              ref={dt}
              value={listSupports}
              dataKey="id"
              paginator
              lazy
              first={lazyParams.first}
              rows={lazyParams.rows}
              rowsPerPageOptions={[10, 20, 50, 100]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} - {last} of {totalRecords}"
              totalRecords={totalRecords}
              emptyMessage="No data found."
              header={header}
              showGridlines
              scrollable
              size="small"
              scrollDirection="both"
              onPage={onPage}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
            >
              <Column
                body={actionBodyTemplateList}
                style={{ flexGrow: 1, flexBasis: "63px" }}
              />
              <Column
                field="user"
                header="User"
                // sortable
                style={{ flexGrow: 1, flexBasis: "250px" }}
                body={(rowData) => (
                  <span>{rowData.user.fullName || rowData.user.username}</span>
                )}
              />

              <Column
                field="email"
                header="Email"
                // sortable
                style={{ flexGrow: 1, flexBasis: "300px" }}
                body={(rowData) => (
                  <span>{rowData.user.email || rowData.user.email}</span>
                )}
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "200px" }}
                field="title"
                header="Title"
                // sortable
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "160px" }}
                field="status"
                header="Status"
                body={(rowData) => (
                  <span
                    className={`v-badge status-${rowData.status.toLowerCase()}`}
                  >
                    {rowData.status}
                  </span>
                )}
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "200px" }}
                field="createdAt"
                header="Created At"
                body={(rowData) => formatLocalTime(rowData.createdAt)}
              ></Column>
            </DataTable>
          )}

          <VDialog
            ref={refDialogDetail}
            header="Support Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              setting={data}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancle={onCancleDialogDetail}
            />
          </VDialog>
          <VDialog
            ref={refDialogDetailSupport}
            header="Support Details"
            onSubmit={onSubmitDetailSupport}
          >
            <DetailSupport
              ref={refDetailSupport}
              data={detail}
              setting={listSupports}
              reload={getSupports}
              toast={toast}
              setLoading={setLoadingSaveDetailSupport}
              onCancle={onCancleDialogDetailSupport}
            />
          </VDialog>
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
