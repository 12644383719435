/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../../layouts/AppTopbar";
import { AppFooter } from "../../layouts/AppFooter";
import { AppMenu } from "../../layouts/AppMenu";
import { AppConfig } from "../../layouts/AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import Dashboard from "./dashboards";
import Empty from "./empty";
import Users from "./users";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";
import Kycs from "./kycs";
import Uploads from "./uploads";
import Supports from "./supports";
import Balances from "./balances";
import Positions from "./settings/positions";
import TransactionInvestments from "./transactions/investments";
import TransactionProfitInvestments from "./transactions/profit-investments";
import TransactionChanges from "./transactions/changes";
import TransactionSwaps from "./transactions/swaps";
import TransactionDeposits from "./transactions/deposits";
import TransactionWithdraws from "./transactions/withdraws";
import Stocks from "./stocks";
import Notifications from "./notifications";
import Allotments from "./allotments";
import LandingPages from "./settings/landing-pages";
import Banners from "./banners";
import QuestionAndAnswer from "./q&a";
import Policy from "./policy";
import SettingWebConfig from "./settings/web-config";
import ComingSoon from "./settings/coming-soon";
import Menus from "./settings/menus";
import Categories from "./settings/categories";
import CategoriesGroup from "./settings/categories-groups";
import Ads from "./settings/ads";
import UserDonate from "./settings/user-donate/View";
import PriceCorrection from "./price-correction";
import Orders from "./orders";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef: any = useRef();
  const location = useLocation();
  const { auth, getStocks, getUsers, getNotifyPending, getSettings } =
    useContext(MasterContext);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (auth) {
      getUsers();
      getStocks();
      getSettings();
    }
  }, [auth]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    getNotifyPending();

    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: { preventDefault: () => void }) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: { preventDefault: () => void }) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: {
    preventDefault: () => void;
  }) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
  };

  const onMenuItemClick = (event: { item: { items: any } }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/",
          module: "overview",
        },
      ],
    },
    {
      label: "SYSTEMS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Price Correction",
          icon: "bx bx-money",
          to: "/price-correction",
          module: "systems",
        },
        {
          label: "Support",
          icon: "bx bx-support",
          to: "/supports",
          module: "supports",
        },
        {
          label: "Uploads",
          icon: "bx bx-cloud-upload",
          to: "/uploads",
          module: "uploads",
        },
        {
          label: "Balances",
          icon: "pi pi-fw pi-tablet",
          to: "/balances",
          module: "users",
        },
      ],
    },
    {
      label: "USERS",
      items: [
        {
          label: "Users",
          icon: "bx bxs-user",
          to: "/users",
          badge: "10",
          module: "users",
        },
        {
          label: "Kycs & Verifies",
          icon: "bx bxs-universal-access",
          module: "kycs",
          to: "/kycs",
        },
        {
          label: "Coins",
          icon: "bx bxs-coin-stack",
          to: "/coins",
          module: "coins",
        },
        {
          label: "Allotments",
          icon: "bx bx-add-to-queue",
          to: "/allotments",
          module: "allotments",
        },
        {
          label: "Orders",
          icon: "bx bxs-user",
          to: "/orders",
          badge: "10",
          module: "orders",
        },
        {
          label: "Transactions",
          icon: "bx bx-transfer",
          module: "transactions",
          items: [
            {
              label: "Deposits",
              icon: "bx bx-list-ul",
              to: "/transactions/deposits",
              module: "transactions",
            },
            {
              label: "Withdraws",
              icon: "bx bx-list-ul",
              to: "/transactions/withdraws",
              module: "transactions",
            },
          ],
        },
      ],
    },
    {
      label: "SETTINGS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Web config",
          icon: "pi pi-fw pi-bars",
          to: "/settings/web_config",
          module: "settings",
        },
        // {
        //   label: "Landing page",
        //   icon: "bx bx-home",
        //   to: "/settings/landing-page",
        //   module: "settings",
        // },
        // {
        //   label: "Themes",
        //   icon: "pi pi-palette",
        //   to: "/settings/themes",
        //   module: "settings",
        // },
        {
          label: "Menus",
          icon: "pi pi-fw pi-bars",
          to: "/settings/menus",
          module: "settings",
        },

        {
          label: "Categories",
          icon: "pi pi-fw pi-bars",
          to: "/settings/categories",
          module: "settings",
        },
        {
          label: "Categories Group",
          icon: "pi pi-fw pi-bars",
          to: "/settings/categories-group",
          module: "settings",
        },
      ],
    },
  ];

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Dashboard colorMode={layoutColorMode} location={location} />
              )}
            />
            <Route path="/users" component={Users} />
            <Route path="/kycs" component={Kycs} />
            <Route path="/uploads" component={Uploads} />
            <Route path="/banners" component={Banners} />
            <Route path="/supports" component={Supports} />
            <Route path="/balances" component={Balances} />
            <Route path="/coins" component={Stocks} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/allotments" component={Allotments} />
            <Route path="/qa" component={QuestionAndAnswer} />
            <Route path="/policy" component={Policy} />
            <Route
              path="/transactions/investments"
              component={TransactionInvestments}
            />
            <Route
              path="/transactions/profit-investments"
              component={TransactionProfitInvestments}
            />
            <Route
              path="/transactions/deposits"
              component={TransactionDeposits}
            />
            <Route
              path="/transactions/withdraws"
              component={TransactionWithdraws}
            />
            <Route path="/transactions/swaps" component={TransactionSwaps} />
            <Route
              path="/transactions/changes"
              component={TransactionChanges}
            />
            <Route path="/settings/web_config" component={SettingWebConfig} />
            <Route
              path="/settings/advertisement-position"
              component={Positions}
            />
            <Route path="/settings/landing-page" component={LandingPages} />
            <Route path="/settings/coming-soon" component={ComingSoon} />
            <Route path="/settings/menus" component={Menus} />
            <Route path="/settings/categories" component={Categories} />
            <Route path="/settings/ads" component={Ads} />
            <Route
              path="/settings/categories-group"
              component={CategoriesGroup}
            />
            <Route path="/settings/users-donate" component={UserDonate} />
            <Route path="/price-correction" component={PriceCorrection} />
            <Route path="/orders" component={Orders} />
            <Route path="*" component={Empty} />
          </Switch>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default App;
