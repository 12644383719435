/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import VLocation from "components/v-location";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    avatar: "",
    full_name: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    referred_by: "",
    feature: {
      is_withdraw: true,
      is_deposit: true,
      is_trade: true,
    },
    status_kyc: null,
    address: {
      country: null,
      province: null,
      district: null,
      ward: null,
    },
  };
  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onInputCheckChange = (e, name, field = null) => {
    const val = e.value || false;
    let _details = { ...details };
    if (field) {
      _details[`${field}`][`${name}`] = val;
      setDetails(_details);
    } else {
      _details[`${name}`] = val;
      setDetails(_details);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await UserService.createUser({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        {details.avatar && (
          <img
            src={details.avatar}
            alt={details.avatar}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Email</label>
        <InputText
          id="email"
          value={details.email}
          onChange={(e) => onInputChange(e, "email")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Name</label>
        <InputText
          id="full_name"
          value={details.full_name}
          onChange={(e) => onInputChange(e, "full_name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Phone</label>
        <InputText
          id="phone"
          value={details.phone}
          onChange={(e) => onInputChange(e, "phone")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Role</label>
        <Dropdown
          defaultValue={details.role}
          value={details.role}
          options={[
            {
              label: "USER",
              value: "USER",
            },
            {
              label: "ADMIN",
              value: "ADMIN",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "role")}
          optionLabel="label"
          placeholder="Select role"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            {
              label: "Confirmed",
              value: "confirmed",
            },
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Blocked",
              value: "blocked",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status Kyc</label>
        <Dropdown
          value={details.status_kyc}
          options={[
            {
              label: "Declined",
              value: "declined",
            },
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Approved",
              value: "approved",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "status_kyc")}
          optionLabel="label"
          placeholder="Select status"
          showClear
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID Sponsor</label>
        <InputText
          id="referred_by"
          value={details.referred_by}
          onChange={(e) => onInputChange(e, "referred_by")}
          required
        />
      </div>
      <div className="field col-12">
        <label htmlFor="name">Location </label>
        <VLocation
          address={details.address}
          setAddress={(a) => onChange(a, "address")}
        />
      </div>

      <div className="field col-6 md:col-3">
        <label htmlFor="is_swap">Is Trade</label>
        <br />
        <InputSwitch
          checked={details.feature.is_trade}
          onChange={(e) => onInputCheckChange(e, "is_trade", "feature")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_deposit">Is Deposit</label>
        <br />
        <InputSwitch
          checked={details.feature.is_deposit}
          onChange={(e) => onInputCheckChange(e, "is_deposit", "feature")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_withdraw">Is Withdraw</label>
        <br />
        <InputSwitch
          checked={details.feature.is_withdraw}
          onChange={(e) => onInputCheckChange(e, "is_withdraw", "feature")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
