import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const deleted = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`uploads/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`uploads/`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllFile = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`uploads?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const uploadSingle = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`uploads/single`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data?.filePath)
          resolve({
            filePath: process.env.REACT_APP_API_URL + "/" + data.filePath,
          });
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UploadService = {
  create,
  deleted,
  getAllFile,
  uploadSingle,
};

export default UploadService;
