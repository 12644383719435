import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/users/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserAuth = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/userAuth")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUsers = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/users?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/users/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateUser = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/users/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createUser = (data: { body: any }) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/users", data.body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const deleteUser = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/users/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else {
          reject(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateToActive = (id: string) =>
  new Promise((rs, rj) => {
    request()
      .put("admin/users/active/" + id)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const updateToBlock = (id: string) =>
  new Promise((rs, rj) => {
    request()
      .put("admin/users/block/" + id)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const updateToUnblock = (id: string) =>
  new Promise((rs, rj) => {
    request()
      .put("admin/users/unblock/" + id)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const getUserBalances = (req?: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/finances?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resetPasswordByAdmin = (userId: string) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/users/reset-password/${userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserAllotmentAvailableToken = (req?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/finances/check-users-allotment`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserBranchAboveByUserId = (req?: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/users/getUserBranchAboveByUserId/${req.params.userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserService = {
  getUserBalances,
  getUserAuth,
  getUsers,
  getUserById,
  updateUser,
  createUser,
  deleteUser,
  updateToActive,
  updateToBlock,
  updateToUnblock,
  search,
  resetPasswordByAdmin,
  getUserAllotmentAvailableToken,
  getUserBranchAboveByUserId,
};

export default UserService;
