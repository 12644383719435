import { useState, createContext } from "react";
import SettingService from "services/settings";
import StockService from "services/stocks";
import SystemService from "services/systems";
import UserService from "services/users";

type MasterContext = {
  auth?: any;
  users?: any;
  stocks?: any;
  getUsers?: any;
  getStocks?: any;
  setAuth?: any;
  notifyPending: any;
  locations?: any;
  getLocations?: any;
  getNotifyPending: any;
  getSettings?: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [users, setUsers] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [auth, setAuth] = useState(null);
  const [notifyPending, setNotifyPending] = useState({});
  const [locations, setLocations] = useState<any>([]);

  const getStocks = () => {
    StockService.getAllCryptos().then((res: any) => {
      if (res && res.cryptos) {
        const _stocks = res.cryptos.map((s) => ({
          ...s,
          fullName: `${s.name} (${String(s.symbol).toUpperCase()})`,
        }));
        setStocks(_stocks);
      }
    });
  };
  const getLocations = () => {
    SettingService.getLocations().then((resp: any) => {
      if (resp && resp.length) {
        setLocations(resp);
      }
    });
  };

  const getNotifyPending = () => {
    SystemService.getCountPending().then((res: any) => {
      if (res && res.data) setNotifyPending(res.data);
    });
  };

  const getUsers = () => {
    UserService.getUsers({
      query: {
        search: "",
      },
    }).then((res: any) => {
      if (res && res.users) setUsers(res.users);
    });
  };

  const getSettings = async () => {
    const res: any = await SettingService.getSettings();
  };

  return (
    <MasterContext.Provider
      value={{
        auth,
        users,
        stocks,
        notifyPending,
        locations,
        getStocks,
        getUsers,
        setAuth,
        getNotifyPending,
        getSettings,
        getLocations,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
