/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Users from "services/users";

import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";
import { renderAmount, renderText } from "utils/render";
import debounce from "lodash.debounce";
import { Calendar, Dropdown, InputText, Panel } from "primereact";
import { formatNumber } from "utils/common";

const Crud = () => {
  const { stocks } = useContext(MasterContext);
  const [listBalances, setListBalances] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [type, setType] = useState("balances");
  const [sum, setSum] = useState([]);

  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 20,
    page: 0,
    sortField: "vnex",
    sortOrder: -1,
  });

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter, type]);

  const loadData = async () => {
    try {
      const res: any = await Users.getUserBalances({
        query: {
          ...lazyParams,
          ...globalFilter,
          type,
          sortField: `${type}.${lazyParams.sortField}`,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setSum(res.sum);
        setListBalances(res.docs);
        setTotalRecords(res.totalDocs);
      } else setListBalances([]);
    } catch (error) {}
  };

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-4">
            <i className=" ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-4">
            <Calendar
              className=" w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              showTime
              showSeconds
              showButtonBar
            />{" "}
          </div>
          <div className="col-12 md:col-4">
            <Calendar
              className=" w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  const footer = (
    <div>
      {Object.keys(sum[0] || {}).map((s) => {
        return (
          <div>
            <span>
              {" "}
              {s} : {formatNumber(Math.abs(sum[0][s]))}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <DataTable
            ref={dt}
            value={listBalances}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            footer={footer}
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="ID"
              header="User ID"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => <span>{rowData?.user?.refer_code}</span>}
            />
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => (
                <span>{renderText(rowData.user?.email as string, 25)}</span>
              )}
            />
            {stocks
              .filter((s) => s.status === "enable")
              .map((crypto, key) => (
                <Column
                  sortable
                  key={key}
                  style={{ flexGrow: 1, flexBasis: "200px" }}
                  field={crypto.symbol}
                  header={`${crypto.symbol}`.toUpperCase()}
                  body={(rowData) => {
                    switch (type) {
                      case "balances":
                        return renderAmount(
                          rowData.balances?.[crypto.symbol] || 0
                        );
                      case "locked_balances":
                        return renderAmount(
                          rowData.locked_balances?.[crypto.symbol] || 0
                        );
                      case "trade_balances":
                        return renderAmount(
                          rowData.trade_balances?.[crypto.symbol] || 0
                        );
                      default:
                        break;
                    }
                  }}
                />
              ))}
            {/* <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="total"
              header="Total VND"
              body={(rowData) =>
                renderAmount(rowData.balance["total_vnex"] || 0)
              }
            /> */}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
