/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import KycService from "services/kycs";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const emptyData = {
    status: "pending",
    note: "",
    type: "",
    frontImage: "",
    backImage: "",
    idNumber: "",
    user: null,
  };

  const optionStatus = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Declined",
      value: "declined",
    },
  ];

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending_kyc") {
      setReadOnly(true);
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await KycService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={optionStatus}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <InputText id="type" value={details.type} readOnly />
      </div>

      <div className="field col-12 ">
        <label htmlFor="description">ID Number</label>
        <InputText id="idKyc" value={details.idNumber} readOnly />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Note</label>
        <InputTextarea
          id="note"
          value={details.note}
          readOnly
          autoResize
          rows={3}
        />
      </div>
      {details.frontImage && (
        <div className="col-12 md:col-4">
          <label htmlFor="front">Front image:</label>
          <br></br>
          <Image src={details.frontImage} width="150" height="150" preview />
        </div>
      )}
      {details.backImage && (
        <div className="col-12 md:col-4">
          <label htmlFor="back">Back image:</label>
          <br></br>
          <Image src={details.backImage} width="150" height="150" preview />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
