import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact";
import { useContext } from "react";

const View = ({ value, setValue, className = "" }) => {
  const { stocks } = useContext(MasterContext);
  console.log({
    stocks,
  });

  return (
    <Dropdown
      value={value}
      className={className}
      options={stocks}
      onChange={(e) => setValue(e.value)}
      optionLabel="fullName"
      filterBy="fullName"
      optionValue="symbol"
      filter
      showClear
      placeholder="Select token"
    />
  );
};

export default View;
